import $ from 'jquery'

$(window).on('load scroll', function(){
  if ($(window).scrollTop() > 200) {
   $('#header-sp').css('background-color','#fff')
  } else {
   $('#header-sp').css('background-color','initial')
  }
})

